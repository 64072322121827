import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CookieModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const rejectCookies = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <ModalContainer>
        <ModalContent>
          <ModalText>
            <p>
              Utilizamos cookies e tecnologias semelhantes para melhorar a sua experiência em nossos serviços,
              personalizar publicidade e recomendar conteúdo de seu interesse. <br/><br/>Ao utilizar nossos serviços, você
              concorda com nossa{' '}
              <a href="/docs/politica-de-cookies.pdf" target="_blank" rel="noopener noreferrer">
                Política de Cookies
              </a>{' '}
              e com nossa{' '}
              <a href="/docs/politica-de-privacidade.pdf" target="_blank" rel="noopener noreferrer">
                Política de Privacidade
              </a>.
            </p>
            <div className="cookie-links">
              <a href="/docs/politica-de-cookies.pdf" target="_blank" rel="noopener noreferrer">
                Política de Cookies
              </a>
              <a href="/docs/politica-de-privacidade.pdf" target="_blank" rel="noopener noreferrer">
                Política de Privacidade
              </a>
            </div>
          </ModalText>
          <ModalButtons>
            <button className="cookie-btn accept-cookies" onClick={acceptCookies}>
              Aceitar
            </button>
            <button className="cookie-btn reject-cookies" onClick={rejectCookies}>
              Rejeitar
            </button>
          </ModalButtons>
        </ModalContent>
      </ModalContainer>
    )
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #000000c7;;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalText = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;

  p {
    font-size: 1rem;
    color: #fff;
  }

  .cookie-links {
    margin-top: 1rem;
    a {
      margin: 0 0.5rem;
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 768px) {
        display: block;
        margin-bottom: 8px;
      }
    }
    @media (max-width: 768px) {
        display: block;
    }
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  .cookie-btn {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .accept-cookies {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    &:hover {
      background-color: #218838;
    }
  }

  .reject-cookies {
    background-color: #4d4646;
    color: white;
    &:hover {
      background-color: #c82333;
    }
  }
`;

export default CookieModal;
