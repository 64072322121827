import React, { useState } from "react";
import styled from "styled-components";

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <Nav>
      <LogoItem onClick={() => window.location.href = "#banner"}><img src="/images/logo-nav.png" alt="Sispecon" /></LogoItem>
      <Menu className={menuActive ? "active" : ""}>
        <MenuItem href="#banner">Home</MenuItem>
        <MenuItem href="#quem-somos">Quem Somos</MenuItem>
        <MenuItem href="#servicos">Serviços</MenuItem>
        <MenuItem href="#certificado">Certificação</MenuItem>
        <MenuItem href="#clientes">Clientes</MenuItem>
        <MenuItem href="#contato">Contato</MenuItem>
      </Menu>
      <HamburgerIcon onClick={toggleMenu}>☰</HamburgerIcon>
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

    @media (max-width: 768px) {
      padding: 1rem 5%;
    
  }
`;

const LogoItem = styled.div`
cursor: pointer;

  @media (max-width: 768px) {
    img {
      width: 190px;
    }
  }
`;
const Menu = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 2rem;
    background-color: ${({ theme }) => theme.colors.darkBackground};
    padding: 1rem;
    border-radius: 0.5rem;
    display: none;

    &.active {
      display: flex;
    }
  }
`;

const MenuItem = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 768px) {
    display: block;
  }
`;

export default Navbar;
