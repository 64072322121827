import React from "react";
import styled from "styled-components";

const Servicos = () => {
  return (
    <ServicosSection id="servicos">
      <Container>
        <h2>Área de Atuação</h2>
        <Grid className="atuacao">
          <ServiceCard>
            <img src="/images/condominio.jpg" alt="Condomínios" />
            <p>Condomínios</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/industria.jpg" alt="Indústrias" />
            <p>Indústrias</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/comercio.jpg" alt="Comércio" />
            <p>Comércio</p>
          </ServiceCard>
        </Grid>
      </Container>
      <Container>
        <h2>Nossos Serviços</h2>
        <Grid>
          <ServiceCard>
            <img src="/images/portaria.png" alt="Serviços Portaria" />
            <p>Serviços Portaria</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/limpeza.png" alt="Serviços de Limpeza" />
            <p>Serviços de Limpeza</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/serv-vigia.png" alt="Serviços de Vigia" />
            <p>Serviços de Vigia</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/gest-acessos.png" alt="Gestão de Acesso" />
            <p>Gestão de Acesso</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/zeladoria.png" alt="Serviços de Zeladoria" />
            <p>Serviços de Zeladoria</p>
          </ServiceCard>
          <ServiceCard>
            <img src="/images/estacionamento.png" alt="Estacionamento Valet" />
            <p>Estacionamento Valet</p>
          </ServiceCard>
        </Grid>
      </Container>
    </ServicosSection>
  );
};

const ServicosSection = styled.section`
  background-color: ${({ theme }) => theme.colors.darkBackground};
  color: ${({ theme }) => theme.colors.white};
  padding: 8rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 2rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  &.atuacao {
    padding-bottom: 4rem;

    img {
      opacity: 0.2;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background-color: #1a1a1a;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s;
  position: relative;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: auto;
  }

  p {
    padding: 1rem;
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;

export default Servicos;
