import React from "react";
import styled from "styled-components";

const QuemSomos = () => {
  return (
    <QuemSomosSection id="quem-somos">
      <Container>
        <TextContent>
          <h2>Quem Somos</h2>
            <p>
            Na SISPECON, unimos tradição e inovação para redefinir a
            segurança patrimonial. Fundada por visionários com vasta
            experiência em segurança pública, nossa missão é
            transformar desafios em soluções modernas e tecnológicas.
            Desde 2001, em Curitiba-PR, nos destacamos por oferecer
            planejamento estratégico em segurança privada, garantindo
            que cada empreendimento receba a proteção e o conforto
            que merece. 
            </p>
            <p>
            Nosso compromisso é com a excelência,
            assegurando que cada cliente sinta-se seguro e valorizado.
            </p>
        </TextContent>
        <ImageContent>
          <img src="/images/car.png" alt="Equipe Sispecon" />
        </ImageContent>
      </Container>
    </QuemSomosSection>
  );
};

const QuemSomosSection = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: 8rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
  }
`;

const ImageContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    border-radius: 10px;
    transition: transform 0.3s;
      &:hover {
        transform: scale(1.05);
    }
  }
`;

export default QuemSomos;
