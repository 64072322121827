import React, { useState } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const Contato = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    mensagem: "",
  });

  const [errors, setErrors] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const emailRegex = /^[^\s()<>@,;:\/]+@\w[\w.-]+\.[a-z]{2,}$/i;

  const validate = () => {
    const newErrors = {};

    if (!formData.nome.trim()) {
      newErrors.nome = "O nome é obrigatório.";
    }
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Por favor, insira um e-mail válido.";
    }
    if (!formData.telefone.trim()) {
      newErrors.telefone = "O telefone é obrigatório.";
    }
    if (formData.mensagem.length < 10) {
      newErrors.mensagem = "A mensagem deve ter pelo menos 10 caracteres.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isVerified) {
      setModalMessage({ type: "error", text: "Por favor, verifique o reCAPTCHA." });
      return;
    }

    if (!validate()) {
      return;
    }

    try {
      await emailjs.send(
        "sitesispecon", 
        "template_dq1tsou", 
        formData,
        "OE71w3wzGuC6RRfOG" 
      );

      setModalMessage({ type: "success", text: "Mensagem enviada com sucesso!" });
      setFormData({ nome: "", email: "", telefone: "", mensagem: "" });
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      setModalMessage({ type: "error", text: "Ocorreu um erro ao enviar. Tente novamente." });
    }
  };

  const handleRecaptchaChange = (value) => {
    setIsVerified(!!value);
  };

  return (
    <Section id="contato">
      <Title>Contato</Title>
      <TextColumn>
        <p>
          A Sispecon oferece soluções completas em portaria e conservação para
          condomínios e empresas. Nosso compromisso é garantir a segurança,
          eficiência e bem-estar de nossos clientes. Entre em contato conosco para
          saber mais sobre nossos serviços.
        </p>
      </TextColumn>
      <Content>
        <FormColumn>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="nome"
              placeholder="Seu Nome"
              value={formData.nome}
              onChange={handleChange}
            />
            {errors.nome && <Validation>{errors.nome}</Validation>}
            <Input
              type="email"
              name="email"
              placeholder="Seu E-mail"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <Validation>{errors.email}</Validation>}
            <Input
              type="text"
              name="telefone"
              placeholder="Seu Telefone (WhatsApp)"
              value={formData.telefone}
              onChange={handleChange}
            />
            {errors.telefone && <Validation>{errors.telefone}</Validation>}
            <TextArea
              name="mensagem"
              placeholder="Sua Mensagem"
              value={formData.mensagem}
              onChange={handleChange}
            />
            {errors.mensagem && <Validation>{errors.mensagem}</Validation>}
            <RecaptchaContainer>
              <ReCAPTCHA
                sitekey="6Leq0YIqAAAAAEtzoUhsi9AWe6BXrDnvRteqBx-z" // Substitua pela sua chave pública do reCAPTCHA
                onChange={handleRecaptchaChange}
              />
            </RecaptchaContainer>
            <Button type="submit">Enviar Mensagem</Button>
          </Form>
        </FormColumn>

        <MapColumn>
          <MapContainer>
            <iframe
              title="Sispecon Location Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.2045475314503!2d-49.283011012624215!3d-25.498223877614066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcfb3b57786b7b%3A0x206a25e8ec60a619!2sSISPECON!5e0!3m2!1spt-BR!2sbr!4v1732058888029!5m2!1spt-BR!2sbr"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </MapContainer>
        </MapColumn>
      </Content>

      {modalMessage && (
        <Modal type={modalMessage.type}>
          <p>{modalMessage.text}</p>
          <button onClick={() => setModalMessage(null)}>Fechar</button>
        </Modal>
      )}
    </Section>
  );
};


const Validation = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
`;

const Section = styled.section`
  padding: 8rem 10%;
  background-color: ${({ theme }) => theme.colors.lightBackground};
`;

const Title = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
  }
`;

const FormColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  resize: none;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  font-size: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const RecaptchaContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

const MapColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
`;

const TextColumn = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.darkText};
  max-width: 930px;
  margin: 40px auto;
  text-align: center;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ type }) => (type === "success" ? "#4CAF50" : "#F44336")};
  color: white;
  padding: 2rem;
  border-radius: 5px;
  text-align: center;

  p {
    margin-bottom: 1rem;
  }

  button {
    background: white;
    color: ${({ type }) => (type === "success" ? "#4CAF50" : "#F44336")};
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 3px;
  }
`;

export default Contato;
