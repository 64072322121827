import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LogoImage from "../logo.svg";  

const Logo = ({ className }) => {
  return <StyledLogo className={className} src={LogoImage} alt="Sispecon" />;
};

Logo.propTypes = {
  className: PropTypes.string,
};

const StyledLogo = styled.img`
  max-width: auto;
  height: auto;
`;

export default Logo;