import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

// Definindo o componente
const Clientes = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Section id="clientes">
      <Title>Clientes</Title>
      <Slider {...settings}>
        <ClientItem>
          <ClientImage src="/images/3re-mbalagens.png" alt="3R Embalagens" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/carto-bento.png" alt="Carto Bento" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/parque-residencial-fazendinha.png" alt="Parque Residencial Fazendinha" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/colegio-amplacao.png" alt="Colégio Amplação.png" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/cooperflux.png" alt="CooperFlux" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/londonlog.png" alt="Londonlog" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/uniandrade.png" alt="Uniandrade" />
        </ClientItem>
        {/* <ClientItem>
          <ClientImage src="/images/condominio-plaza-mayor.png" alt="Condomínio Plaza Mayor" />
        </ClientItem> */}
        <ClientItem>
          <ClientImage src="/images/recanto-suico-du-valais.png" alt="Recanto Suíço Du Valais" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/escola-ursula-benincasa.png" alt="Escola Ursula Benincasa" />
        </ClientItem>
        <ClientItem>
          <ClientImage src="/images/master-cargas.png" alt="Master Cargas" />
        </ClientItem>
      </Slider>
    </Section>
  );
};

// Estilização com styled-components
const Section = styled.section`
  padding: 8rem 2rem;
  background-color: ${({ theme }) => theme.colors.text};
`;

const Title = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const ClientItem = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.02);
    }
  
`;

const ClientImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
`;

export default Clientes;
