import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Ubuntu', sans-serif;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  .mouse {
    width: 25px;
    height: 45px;
    border: 2px solid #fff;
    position: absolute;
    bottom: 100px;
    left: 50%;
    margin-left: -12.5px;
    border-radius: 12px;
}
@-webkit-keyframes rotateplane {
    0%   {top:10px;}
    25%  {top:14px;}
    50%  {top:19px;}
    75%  {top:24px;}
    100% {top:28px;}
}
@-moz-keyframes rotateplane {
    0%   {top:10px;}
    25%  {top:14px;}
    50%  {top:19px;}
    75%  {top:24px;}
    100% {top:28px;}
}
@-o-keyframes rotateplane {
    0%   {top:10px;}
    25%  {top:14px;}
    50%  {top:19px;}
    75%  {top:24px;}
    100% {top:28px;}
}
.mouse::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #fff;
    border-radius: 100%;
    left: 50%;
    top:10px;
    margin-left: -2.5px;
    transition:all 0.3s ease-in;

     -webkit-animation-name: rotateplane; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
    animation-name: rotateplane;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
`;

export default GlobalStyles;
