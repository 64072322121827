import React from "react";
import styled from "styled-components";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import Logo from "./Logo";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <AboutColumn>
          <Logo className="logofooter" /> 
          <p>
            A Sispecon é uma empresa especializada em soluções de portaria e conservação. Nosso compromisso é garantir a segurança e o bem-estar de nossos clientes, com serviços de qualidade e eficiência.
          </p>
        </AboutColumn>
        <LinksColumn>
          <H4Title>Links Importantes</H4Title>
          <ul>
            <li><a href="#quem-somos">Quem Somos</a></li>
            <li><a href="#servicos">Serviços</a></li>
            <li><a href="#contato">Contato</a></li>
            <li><a href="#certificado">Certificação</a></li>
            <li><a href="/docs/politica-de-privacidade.pdf">Política de Privacidade</a></li>
            <li><a href="/docs/politica-de-cookies.pdf">Política de Cookies</a></li>
          </ul>
        </LinksColumn>
        <SocialColumn>
          <H4Title>Redes Sociais</H4Title>
          <div>
            <a href="https://www.facebook.com/sispecon" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://www.instagram.com/sispeconseguranca" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://www.linkedin.com/sispecon" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href="https://api.whatsapp.com/send?phone=5541984441020&text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es,%20voc%C3%AA%20pode%20me%20ajudar?" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
          </div>
        </SocialColumn>
        <ContactColumn>
          <H4Title>Contato</H4Title>
          <p>Telefone: (41) 9 8531-1020</p>
          <p>Email: <a href="mailto:administrativo@sispecon.com.br">administrativo@sispecon.com.br</a></p>
          <p>Endereço: Rua Emanoel Kant, 60 | 3 andar, sala 305 | Edificio H.A Offices | Capão Raso | CEP 81020670 | Curitiba/PR</p>
        </ContactColumn>
      </FooterContent>
      <FooterBottom>
        <p>&copy; 2024 Sispecon - Todos os direitos reservados.</p>
        <p>desenvolvidor por <a href="http://avenci.com.br">avenci | consultoria e marketing</a></p>

      </FooterBottom>
    </FooterContainer>
  );
};

// Estilos do Footer (como antes)
const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.darkBackground};
  color: ${({ theme }) => theme.colors.white};
  padding: 4rem 0 0;
`;

const H4Title = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 80%;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
`;

const AboutColumn = styled.div`
  flex: 2;
  max-width: 300px;
  .logofooter {
    width: 190px;
    height: auto;
    margin-bottom: 1rem;
  }
  p{
    line-height: 1.6;
    font-size: 0.8rem;
  }
     @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
`;

const LinksColumn = styled.div`
  flex: 1;
  max-width: 200px;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin-bottom: 0.5rem;
      a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        &:hover {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
`;

const ContactColumn = styled.div`
  flex: 2;
  max-width: 300px;

  p {
    line-height: 1.6;
  }

    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
`;

const SocialColumn = styled.div`
  display: block;
  gap: 1rem;

  div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {

      justify-content: space-around;
    }
  }
  a {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }

`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;  
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8rem;

  a {
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
}
    @media (max-width: 768px) {
        display: block;
        text-align: center;

        p {
          margin-bottom: 0.5rem;
        }
    }
`;

export default Footer;
