import React from "react";
import styled from "styled-components";

const Certificados = () => {
  return (
    <CertificadosSection id="certificado">
      <Container>
        <ImageContent>
          <img src="/images/lgpd.png" alt="Certificados" />
        </ImageContent>
        <TextContent>
          <h2>Certificado
          & Selo LGPD</h2>
          <p>
          Nossa equipe investe continuamente em tecnologias 
          avançadas e práticas inovadoras para manter a conformidade 
          com a <strong>Lei Geral de Proteção de Dados (LGPD)</strong>. Isso significa 
          que você pode contar com processos transparentes e seguros, 
          desde o momento em que compartilha suas informações conosco. 
          Além disso, nossos sistemas são submetidos a auditorias 
          regulares e atualizações constantes, garantindo que a 
          proteção dos seus dados esteja sempre alinhada às melhores 
          práticas do mercado.
          </p>
          <p>
          Ao escolher nossos serviços, você opta por uma experiência 
          que prioriza a privacidade e a segurança. <strong>O Selo LGPD</strong> é mais 
          do que um compromisso: é a nossa garantia de que suas informações 
          estarão protegidas contra acessos não autorizados, enquanto você 
          desfruta de um ambiente digital confiável e respeitoso. 
          Estamos aqui para fortalecer a sua confiança em cada etapa da sua jornada conosco.
          </p>
        </TextContent>
      </Container>
    </CertificadosSection>
  );
};

const CertificadosSection = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 8rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ImageContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    border-radius: 10px;
    transition: transform 0.3s;
      &:hover {
        transform: scale(1.05);
    }
  }
`;

export default Certificados;
