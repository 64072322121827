import React from "react";
import styled from "styled-components";

const Equipe = () => {
  return (
    <EquipeSection id="quem-somos">
      <Container>
        <TextContent>
          <h2>Profissionais<br/> Treinados</h2>
            <p>
            Na <strong>SISPECON</strong>, nossos profissionais são a essência da nossa
            excelência. Cada colaborador é rigorosamente treinado e
            plenamente ciente da importância de suas funções. Preparados
            para atender às demandas mais exigentes, eles estão
            equipados para implementar procedimentos preventivos de
            controle com eficácia. Nosso compromisso é garantir que cada
            membro da equipe esteja capacitado para oferecer soluções de
            segurança que proporcionem tranquilidade e confiança aos
            nossos clientes.
            </p>
        </TextContent>
        <ImageContent>
          <img src="/images/equipe.jpg" alt="Equipe Sispecon" />
        </ImageContent>
      </Container>
    </EquipeSection>
  );
};

const EquipeSection = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: 8rem 0;
  background: url("/images/bg-white.jpg") no-repeat center center;
  background-size: cover;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContent = styled.div`
  flex: 1;

  h2 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.primary};
  }

      @media (max-width: 768px) {
        background-color: #1a1a198c;
        text-align: center;
        width: 90%;
        padding: 24px;
        border-radius: 8px;
        color: white;

        strong {
          color: ${({ theme }) => theme.colors.white};
        }
      }
`;

const ImageContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: 100%;
    border-radius: 10px;
    transition: transform 0.3s;
      &:hover {
        transform: scale(1.05);
    }
  }

      @media (max-width: 768px) {
        width: 90%;
      }
`;

export default Equipe;
