import React from "react";
import styled from "styled-components";

const Gallery = () => {
  const galleryItems = [
    {
      src: "/images/001.png",
      text: "Um local sempre limpo, além de não prejudicar a saúde, melhora o ambiente de	trabalho podendo proporcionar um melhor rendimento dos funcionários e até mesmo um melhor aproveitamento do local.",
    },
    {
      src: "/images/002.png",
      text: "Garanta a segurança do seu estabelecimento comercial com os serviços de Vigia da Sispecon.",
    },
    {
      src: "/images/003.png",
      text: "Escolha uma equipe especializada, Tenha Controle de entrada e Terceirize serviços para empresas especializadas.",
    },
    {
      src: "/images/004.png",
      text: "Nossos serviços são personalizados para cada tipo e tamanho de condomínio residencial. Prezando sempre pela excelência nos serviços prestados.",
    },
    {
      src: "/images/005.png",
      text: "A Sispecon conta com uma equipe de profissionais treinados e qualificados para garantir o bom serviço prestado aos nossos clientes.",
    },
    {
      src: "/images/006.png",
      text: "A limpeza e conservação são mais do que uma questão de estética: são fundamentais para a saúde, segurança e produtividade. Ambientes limpos e bem cuidados não apenas previnem doenças, mas também criam uma atmosfera agradável e acolhedora, valorizando imóveis e fortalecendo a imagem de empresas e residências.",
    },
    {
      src: "/images/008.png",
      text: "O controle de entrada é essencial para garantir a segurança de pessoas e patrimônios. Com profissionais treinados e tecnologia adequada, é possível monitorar acessos, prevenir riscos e criar um ambiente mais protegido. Seja em condomínios, empresas ou eventos, investir em soluções confiáveis é a chave para a tranquilidade e proteção.",
    },
    {
      src: "/images/009.png",
      text: "Contar com novas viaturas significa mais eficiência e confiança na proteção. Veículos modernos, equipados com rastreadores, garantem resposta rápida e suporte contínuo. Além de reduzir custos com manutenção, fortalecem o patrulhamento, oferecendo segurança personalizada e ágil para cada necessidade.",
    },
  ];

  return (
    <GallerySection id="galeria">
      <div>
        <h2>Um pouco de nosso trabalho</h2>
        <hr className="bottom-line" />
      </div>
      <GalleryContainer>
        {galleryItems.map((item, index) => (
          <ImageCard key={index}>
            <img src={item.src} alt={`Galeria ${index + 1}`} />
            <figcaption>
              <p>{item.text}</p>
            </figcaption>
          </ImageCard>
        ))}
      </GalleryContainer>
    </GallerySection>
  );
};

const GallerySection = styled.section`
  padding: 8rem 2rem;
  text-align: center;

  h2 {
    padding: 0 0 2rem;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.primary};
  }

  .bottom-line {
    width: 100px;
    height: 3px;
    background: #333;
    margin: 10px auto;
  }
`;

const GalleryContainer = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

const ImageCard = styled.figure`
  position: relative;
  perspective: 1000px;

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    transition: transform 0.6s ease-in-out;
    backface-visibility: hidden;
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    transition: transform 0.6s ease-in-out, opacity 0.4s ease-in-out;
  }

  &:hover img {
    transform: rotateY(180deg);
  }

  &:hover figcaption {
    transform: rotateY(0);
    opacity: 1;
  }
`;

export default Gallery;
