const theme = {
    colors: {
      primary: "#f28212",
      background: "#fff",
      darkBackground: "#1a1a1a",
      text: "#1a1a1a",
      white: "#fff",
      black: "#000",
    },
  };
  
  export default theme;
  