import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Logo from "./Logo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <BannerSection id="banner">
      <StyledSlider {...settings}>
        <SlideItem>
          <BannerContent style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
            <Logo className="logodesktop"/>
            <p>
              Especialistas em segurança patrimonial e soluções completas para empresas, condomínios e indústrias.
            </p>
          </BannerContent>
          <BannerImage src="/images/banner1.jpg" className="desktop" />
          <BannerImage src="/images/mob-banner1.jpg" className="mobile" />
        </SlideItem>
        <SlideItem>
          <BannerContent className="contentbanner2" style={{ transform: 'translate(-135%, 70px)' }}>
            <h2>
              Excelência em Serviços
            </h2>
            <p>
              Comprometida com a excelência, a <strong>SISPECON</strong> se destaca por sua
              prontidão em atender às necessidades dos clientes. Nossa
              dedicação contínua à qualidade nos permite oferecer soluções que
              não apenas satisfazem, mas superam as expectativas, garantindo
              um serviço de alto padrão em todas as nossas operações.
            </p>
          </BannerContent>
          <BannerImage src="/images/banner-excelencia.jpg" className="desktop" />
          <BannerImage src="/images/mob-banner-excelencia.jpg" className="mobile" />
        </SlideItem>
      </StyledSlider>
      <div className="mouse"></div>
    </BannerSection>
  );
};

const BannerSection = styled.section`
  margin-top: 60px; // Espaço para o navbar fixo
  width: 100%;
  height: calc(100vh - 60px);
`;


const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-dots {
    bottom: 25px;
    
    li button:before {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .slick-prev, .slick-next {
    z-index: 1;
    
    &:before {
      font-size: 30px;
    }

      @media (max-width: 768px) {
        display: none !important; 
      }
  }
    .slick-slide {
      position: relative;
    }

  .slick-prev {
    left: 5% !important;
  }
  .slick-next {
    right: 5% !important;
  }
`;

const BannerContent = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  max-width: 500px;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  h2 {
    color: ${({ theme }) => theme.colors.white};
    padding-bottom: 1rem;
  }

  .logodesktop {
    width: 320px;
    margin-bottom: 1rem;
    transition: transform 0.3s;
      &:hover {
        transform: scale(1.05);
    }
  }
`;
const SlideItem = styled.div`
  height: calc(100vh - 60px);
  position: relative;

  .contentbanner2 {
      @media (max-width: 768px) {
        background-color: #1a1a198c;
        transform: translate(-50%, -50%) !important;
        text-align: center;
        width: 90%;
        padding: 24px;
        border-radius: 8px;
      }
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.mobile {
    display: none;
  }

  @media (max-width: 768px) {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
`;

export default Banner;