import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import theme from "./styles/theme";

import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import QuemSomos from "./components/QuemSomos";
import Equipe from "./components/Equipe";
import Servicos from "./components/Servicos";
import Gallery from "./components/Gallery";
import Certificado from "./components/Certificado";
import Clientes from "./components/Clientes";
import Contato from "./components/Contato";
import Footer from "./components/Footer";
import CookieModal from "./components/CookieModal";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Navbar />
      <Banner />
      <QuemSomos />
      <Servicos />
      <Gallery />
      <Equipe />
      <Certificado />
      <Clientes />
      <Contato />
      <CookieModal />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
